@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@500&family=Montserrat:wght@700&display=swap');

.aboutUs_container_main {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  min-height: 800px;
}

.historico_container {
  height: 50%;
  width: 100%;
  padding: 80px 200px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
};

.titles {
  font-family: 'Bebas Neue', cursive;
  font-size: 36px;
  font-weight: 200;
  color: white !important;
  text-align: center;
  /* margin-bottom: 50px; */
}

.line_serv {
  margin-bottom: 20px;
}

.about {
  color: white;
  padding: 0 100px;
  /* margin-bottom: 40px; */
  font-weight: 100;
  text-align: center;
  /* overflow-x: hidden; */
}

.historico {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: white;
  width: 100%;
  /* margin-bottom: 20px; */
}

.historico li {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
}

/* .historico li:not(:first-of-type)::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #f44336;
} */

.historico li h1 {
  margin-right: 15px;
  font-size: 60px;
  color: #BEFF25;
}

.historico li h3 {
  width: 100px;
  font-family: 'Bebas Neue', cursive, sans-serif;
  font-weight: 100;
  font-size: 25px;
}

.depoiments_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 200px;
  color: #222222;
  width: 100vw;
  height: 40%;
}

.depoiment {
  width: 50%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px !important;
  row-gap: 5px;
  background-color: #f44336;
  padding: 10px 50px;
  position: relative;
}

.timer {
  /* width: 1%; */
  border: solid 2px #BEFF25;
  background-color: #BEFF25;
  position: absolute;
  top: 0;
  left: 0;
  /* transition-duration: 5s; */
  /* animation: timer 5s ease-in-out; */
}

.depoiment h3 {
  color: white;
  font-size: 1em;
}

.icon_aspas {
  color: white;
}

.icon_triangle {
  position: absolute;
  font-size: 5em;
  right: -52px;
  color: #f44336;
}

.img_depoiment {
  width: 50%;
  height: 100%;
  transition-duration: 0.4s;
  animation: surgir 0.4s ease-in-out;
  background-position: center !important;
}

.btn_dep_container {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: -40px;
  left: -8px;
}

.btn_dep {
  /* width: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: white;
  border: none;
  /* padding: 10px 16px; */
  text-align: center;
  text-decoration: none;
  margin: 5px;
  font-size: 30px;
  font-weight: 200;
  transition-duration: 0.4s;
  cursor: pointer;
}

@media (max-width: 1240px) {
  .historico_container {
    padding: 0;
  }

  .about {
    padding: 0 20px;
  }

  .btn_dep_container {
    left: 0;
    bottom: -40px;
    left: -8px;
  }
}

@media (max-width: 900px) {
  .historico_container {
    height: 70%;
  }

  .depoiments_container {
    padding: 0 20px;
    flex-direction: column-reverse;
    width: 100%;
    height: 30%;
    justify-content: flex-end;
  }

  .aboutUs_container_main {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100vh;
  }

  .about {
    text-align: left;
    /* height: 50%; */
    /* overflow-y: scroll; */
    margin: 0 !important;
    padding-right: 20px !important;
    font-size: 1em;
  }

  .titles {
    text-align: center;
    padding: 0 20px;
    margin-top: 20px;
  }

  .depoiment {
    padding: 10px !important;
    font-size: smaller;
    width: 100%;
  }

  .img_depoiment {
    display: none;
    /* width: 100%;
    background-position: top !important;
    background-repeat: repeat !important;
    background-size: cover !important; */
  }

  .icon_triangle {
    transform: rotate(90deg);
    left: 40%;
    bottom: -43px;
  }

  .historico {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    width: 90%;
    margin: 20px 0;
  }

  .historico li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .historico li h1 {
    font-size: 2em !important;
    width: 50%;
    text-align: right;
    margin-left: 0;
  }
  
  .historico li h3 {
    font-size: 1.5em !important;
    width: 50%;
    text-align: left;
  }

}

@media (max-width: 600px) {
  .historico {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    width: 90%;
    margin: 20px 0;
  }

  .historico li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .historico li h1 {
    font-size: 1.5em !important;
    width: 50%;
    text-align: right;
    margin-left: 0;
  }
  
  .historico li h3 {
    font-size: 1em !important;
    width: 50%;
    text-align: left;
  }

}

@keyframes timer {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes surgir {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
