.img_funcionarios {
  display: block !important;
  width: 40% !important;
  /* background-repeat: repeat !important; */
  background-size: cover !important;
}

@media (max-width: 900px) {
  .funcionarios_container {
    height: 40% !important;
  }

  .depoiment_funcionarios {
    height: 50% !important;
    margin-bottom: 40px;
  }

  .img_funcionarios {
    display: block !important;
    width: 100% !important;
    /* background-repeat: repeat !important; */
    background-size: cover !important;
    filter: brightness(0.8) contrast(1.2);
  }

  .historico_about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    width: 90%;
    margin: 20px 0;
  }

  .historico_about li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .historico_about li h1 {
    font-size: 1.5em !important;
    width: 50%;
    text-align: right;
    margin-left: 0;
  }
  
  .historico_about li h3 {
    font-size: 1em !important;
    width: 50%;
    text-align: left;
  }
}