.whatsapp {
  position: fixed;
  color: #BEFF25;
  font-size: 3em;
  bottom: 50px;
  right: 50px;
  z-index: 30000;
}

.wpp_icon {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  animation-iteration-count: 4;
  transition-duration: 0.4s;
  opacity: 0.5;
}

.wpp_icon:hover, .wpp_icon:active {
  opacity: 1;
}

@media (max-width: 1240px) {
  .whatsapp {
    font-size: 2em;
    bottom: 35px;
    right: 10px;
  }
}

@media (max-width: 600px) {
  .whatsapp {
    font-size: 1.5em;
    bottom: 40px;
  }

  .wpp_icon {
    opacity: 0.2;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}