.direitos_container {
  height: 100%;
  width: 100%;
  background-color: #0e0d0d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 0;
}

.julio {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.julio:hover {
  text-decoration: none;
  color: #BEFF25;
}

@media (max-width: 900px) {
  .direitos_container {
    font-size: 12px;
    font-weight: 200;
    text-align: center;
    height: 6vh;
  }

}