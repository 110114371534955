@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@500&family=Montserrat:wght@700&display=swap');

.header_container {
  height: 100vh;
  display: block;
  overflow: hidden;
  min-height: 300px;
}

.header_container_services {
  background-position: bottom !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.txt_header_container {
  font-family: 'Bebas Neue', cursive;
  font-size: 2.5rem;
  position: absolute;
  /* margin-left: 10vw; */
  margin-top: 10vh;
  z-index: 1;
}

.safe {
  color: #BEFF25;
}

.stay_safe {
  position: relative;
  transition: transform 0.3s ease;
  left: 10vw;
}

.stay_safe_2 {
  position: relative;
  opacity: 0.5;
  color: hsla(120, 100%, 50%, 0);
  -webkit-text-stroke-width: 0.8px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
  transition: transform 0.3s ease;
  font-weight: 500;
  left: 10vw;
}

.stay_safe_3 {
  position: relative;
  opacity: 0.2;
  color: hsla(120, 100%, 50%, 0);
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
  transition: transform 0.3s ease;
  font-weight: 200;
  left: 10vw;
}

.white {
  -webkit-text-stroke-color: rgb(255, 255, 255);
}

/* .txt_services {
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #222222;
} */

@media (max-width: 1148px) {

  .txt_header_container {
    margin-left: 20px;
    margin-top: 30px;
  }

  .header_container {
    height: 50vh;
    overflow-x: hidden;
  }

  .stay_safe {
    left: 0;
  }
  
  .stay_safe_2 {
    left: 0;
  }
  
  .stay_safe_3 {
    left: 0;
  }
}

@media (max-width: 600px) {

  .txt_header_container {
    margin-left: 20px;
    margin-top: 25%;
    font-size: 20px;
  }

  /* .txt_services {
    margin-top: 25%;
  } */

  .header_container {
    height: 70vh;
    overflow-x: hidden;
  }
}

@keyframes slidein {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideout {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}