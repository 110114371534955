.details_container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 5vh;
}

.txt_details_container {
  display: flex;
  flex-direction: column;
}

.txt_service {
  color: white;
  font-size: 1.3em;
  margin-bottom: 5vh;
}

.list_service {
  color: white;
  font-size: 1.1em;
}

.list_service li {
  margin-top: 1vh;
  transition: 0.4s ease-in-out;
  list-style: none;
}

.list_service li::before { 
  content: "";
  border-color: transparent #f44336;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 0.9em;
  position: relative;
}

.list_service li:hover {
  color: #BEFF25;
  cursor: pointer;
}

.carousel {
  /* width: 50%; */
}

.thumbs-wrapper {
  /* width: 180%; */
}

@media (max-width: 900px) {
  .details_container {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .txt_details_container {
    /* width: 40%; */
  }

  .txt_service {
    font-size: 1em;
  }
  
  .list_service {
    font-size: 0.9em;
  }

  .carousel {
    /* height: 500px; */
    /* margin-bottom: 120px; */
  }
}
