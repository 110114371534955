.div_container_form {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 200px;
  gap: 20px;
  min-height: 800px;
}

.header_orc {
  min-height: "500px";
}

.img_orc {
  width: 50%;
  height: 60vh;
  margin: 80px 0;
  border-radius: 8px;
  filter: brightness(0.6) contrast(1.2);
}

.form_orcamento {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 80vh;
  gap: 20px;
  margin: 40px 0;
  justify-content: center;
}

.form_orcamento label {
  color: white;
  gap: 5px;
  width: 100%;
}

.input_form_orc {
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  border-radius: 5px;
}

.txtArea_orc {
  height: 200px;
  resize: none;
}

.bttn_env_orc {
  background-color: #f44336;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 10px 20px;
  /* margin: 0 200px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  font-weight: 700;
  transition-duration: 0.4s;
  cursor: pointer;
  width: 200px;
}

.bttn_env_orc:hover {
  background-color: white;
  color: #f44336;
}

@media (max-width: 1240px) {
  .div_container_form {
    flex-direction: column-reverse;
    padding: 0 20px;
  }

  .img_orc {
    width: 90%;
    height: 40vh;
    margin: 20px 0;
  }

  .form_orcamento {
    width: 90%;
    height: 50%;
    padding: 0;
  }

  .div_container_form {
    width: 100%;
    /* margin: 0 20px; */
  }

  .input_form_orc {
    width: 100%;
  }

  .bttn_env_orc {
    margin: 0;
  }

}

@media (max-width: 600px) {
  .div_container_form h1 {
    font-size: 2em;
  }

  .header_orc {
    min-height: "300px";
  }

  .img_orc {
    display: none;
  }

  .whatsapp {
    font-size: 1.5em;
    bottom: 40px;
  }

  .wpp_icon {
    opacity: 0.2;
  }

  .header_orc {
    background-position: right !important;
  }
}