@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@500&family=Montserrat:wght@700&display=swap');

.about_main {
  flex-direction: row;
  min-height: 400px;
}

.about_main div {
  width: 50%;
}

.about_main div p {
  margin: 0;
  padding: 0;
  text-align: justify;
  font-size: 1.2em;
}

.quem_about {
  color: white;
}

.white_color_font {
  -webkit-text-stroke-color: rgb(255, 255, 255);
}

.about_main div h1 {
  font-family: 'Bebas Neue', cursive;
  font-weight: 100;
  font-size: 2em;
}

.about_div_txt {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 50vh;
}

.odenis {
  margin-left: 20px;
  width: 100%;
  height: 50vh;
}

@media (max-width: 900px) {
  .about_main {
    flex-direction: column;
    margin: 0;
    height: 100vh;
  }

  .about_main div {
    width: 100%;
    height: 100%;
  }

  .about_div_txt p {
    text-align: left !important;
    padding-top: 10px !important;
  }

  .odenis {
    margin: 20px 0;
    height: 200px;
  }
}