@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@500&family=Montserrat:wght@700&display=swap');

.services_container_main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  color: white;
}

.titles {
  font-family: 'Bebas Neue', cursive;
  font-size: 36px;
  font-weight: 200;
  color: white;
  /* margin-bottom: 50px; */
}

.line_serv {
  border: solid 1px #f44336;
  width: 200px;
  margin: 20px 0 50px;
}

.container_serv {
  display: flex;
  margin: 0 10vw;
  justify-content: space-between;
}

.btns_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20%;
}

.btn_services {
  background-color: #222222;
  position: relative;
  border: none;
  color: white;
  padding: 12px 24px;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  height: 100%;
  width: 100%;
  font-size: 24px;
  font-weight: 200;
}

.btn_services:not(:last-child) {
  border-bottom: solid 2px darkslategrey;
}

/* .btn_services:first-child {
  background-color: #f44336;
} */

.icon_serv {
  color: white;
  font-size: 1.5em;
  opacity: 0.9;
}

.div_bt_vazia {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 11;
}

.redBtn {
  background-color: #f44336;
}

.container_article_img {
  display: flex;
  width: 80%;
  background-color: #f44336;
}

.container_article_img article {
  display: flex;
  margin: 40px 0 40px 40px;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  width: 50%;
}

.container_article_img article h1 {
  font-family: 'Bebas Neue', cursive;
  font-weight: 200;
  font-size: 28px;
}

.container_article_img article h1::after {
  content: ' :';
}

.container_article_img article p {
  text-align: justify;
  line-height: 30px;
  font-size: 16px;
}

.container_article_img article ul {
  width: 100%;
  padding-left: 10px;
  text-align: left;
}

.continue_text {
  display: none;
}

.img_serv {
  width: 50%;
  height: 70vh;
  border: solid 40px #f44336;
  transition-duration: 0.4s;
  animation: surgir 0.4s ease-in-out;
  background-position: center;
}

@media (max-width: 1650px) {
  .btn_services {
    font-size: 18px;
    padding: 8px 12px;
    gap: 10px;
  }

  .icon_serv {
    font-size: 1.5em;
    resize: none;
  }

  .container_article_img article {
    display: flex;
    margin: 20px 0 20px 20px;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 50%;
  }
  
  .container_article_img article h1 {
    font-family: 'Bebas Neue', cursive;
    font-weight: 100;
    font-size: 24px;
  }

  .container_article_img article p {
    text-align: justify;
    line-height: 20px;
    font-size: 18px;
  }

  .container_article_img p::-webkit-scrollbar {
    width: 12px;
  }

  .img_serv {
    width: 50%;
    height: 60vh;
    border: solid 20px #f44336;
  }
}

@media (max-width: 1240px) {
  .container_serv {
    flex-direction: column;
    margin: 0 20px;
    align-items: center;
    height: 80vh;
    width: 90vw;
    justify-content: stretch;
  }

  .btns_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90vw;
  }

  .btn_services {
    flex-direction: column;
    height: 100%;
    font-size: 0;
    width: 100%;
    z-index: 10;
  }

  .btn_services:not(:last-child) {
    border-right: solid 2px darkslategrey;
    border-bottom: none;
  }

  .icon_serv {
    color: white;
    font-size: 30px;
    opacity: 0.9;
  }

  .container_article_img {
    flex-direction: column;
    width: 100%;
    margin: 0 20px;
    height: 90%;
    align-items: center;
  }

  .container_article_img article {
    align-items: center;
    margin: 10px;
    flex-direction: column;
    /* gap: 10px 0; */
    width: 95%;
    height: 60%;
  }

  .container_article_img article h1 {
    font-size: 20px;
    padding: 15px 0;
    overflow-y: hidden;
    font-size: 18px;
    text-align: justify;
  }

  .container_article_img article p {
    overflow-X: hidden;
    /* text-overflow: ellipsis !important; */
    width: 90%;
    height: 80%;
    text-align: justify;
    font-size: 16px;
  }

  .img_serv {
    width: 100%;
    height: 40%;
    border: solid 10px #f44336;
  }
}

@media (max-width: 600px) {
  .continue_text {
    display: none;
    padding: 0;
    margin: 0;
  }

  .container_article_img article p {
    text-align: left;
  }

  .container_article_img article ul {
    font-size: 14px;
    margin-left: 10px;
  }

}

@keyframes surgir {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}