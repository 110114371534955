.grid_services {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10vw;
}

.serv_img_grid {
  position: relative;
}

.icon_serv_grid {
  color: #BEFF25;
}

/* color: #f44336 #BEFF25 #222222 */

.serv_title {
  display: block;
  position: absolute;
  bottom: 0;
  color: #222222;
  font-size: 2em;
  padding: 10px;
  margin: 0;
  width: 100%;
  transition: 0.5s ease-in-out;
  background-color: rgba(190, 255, 37, 0.7);
}

.serv_img_grid:hover .serv_title {
  background-color: hsla(120, 100%, 50%, 0);
  bottom: 10%;
  color: #BEFF25;
}

.serv_descrip {
  color: white;
}

@media (max-width: 900px) {

  .grid_services {
    padding: 40px 20px;
  }

  .img_serv_container {
    height: 200px;
  }

  .serv_title {
    font-size: 1.2em;
  }

  .serv_descrip {
    font-size: 0.8em;
  }
}