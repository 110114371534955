
.navbar_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 40px;
  margin: 0 10vw;
  padding: 20px;
  border-radius: 10px;
  right: 0;
  left: 0;
  z-index: 12;
}

.active_now {
  color: #BEFF25;
  text-decoration: underline #BEFF25;
}

.h1_logo {
  z-index: 120;
}

.a_logo_link {
  z-index: 120;
}

.logo_nav {
  width: 200px;
  z-index: 120;
}

.logo_nav_mobile {
  width: 200px;
  display: none;
}

.nav_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 80px;
}

.nav_list li {
  list-style: none;
  font-size: 16px;
}

.NL {
  display: flex;
  color: #fff;
  transition-duration: 0.3s;
  align-items: center;
  font-size: 1em;
  font-weight: 100;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* padding-bottom: 5px; */
}

.nav_service {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row;
  overflow: visible;
}

.NL_serv {
  height: 50px;
}

.NL:not(.NL_serv):hover {
  color: #BEFF25;
  text-decoration: underline #BEFF25;
  cursor: pointer;
}

.NL_serv span:hover {
  color: #BEFF25;
  text-decoration: underline #BEFF25;
  cursor: pointer;
}

.NL_serv_div {
  display: flex;
  /* align-items: center; */
}

.icon_serv_nav {
  color: white;
  margin-left: 5px;
  font-size: 1em;
}

.icon_serv_nav:hover {
  color: #BEFF25;
  cursor: pointer;
}

.dropDown_list {
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  top: 20px;
  width: 220px;
  background-color: #222222;
  animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center
}

.dropDown_list li {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  list-style:georgian;
  font-size: 1em;
  font-weight: 100;
  height: 100%;
  width: 100%;
  gap: 5px;
}


.bttn_fale {
  background-color: #f44336;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  font-weight: 700;
  transition-duration: 0.4s;
  cursor: pointer;
}

.bttn_fale:hover {
  background-color: white;
  color: #f44336;
}

.cote_agr {
  display: none;
}

.mobile_menu {
  display: none;
  cursor: pointer;
}

.mobile_menu div {
  width: 32px;
  height: 2px;
  background: #BEFF25;
  margin: 8px;
  transition: 0.3s;
}

@media (max-width: 1420px) {
  .nav_list {
    gap: 40px;
    display: none;
  }
}

@media (max-width: 1148px) {
  .navbar_container {
    margin: 0 20px;
    padding: 24px;
  }

  .logo_nav_mobile {
    display: block;
    position: relative;
    top: -15vh;
    left: -30vw;
    /* margin-bottom: 20vh; */
  }

  .nav_list {
    display: flex !important;
    justify-content: flex-start;
    text-align: center;
    position: fixed;
    top: -15px;
    right: -20px;
    width: 110vw;
    height: 115vh;
    background: #23232e;
    opacity: 0.98;
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 0.3s ease-in;
    z-index: 1000;
    gap: 10px;
    padding: 20vh 0 0 30%;
  }

  .NL_serv {
    height: auto;
    flex-direction: column;
    align-items: center;
  }

  .dropDown_list {
    background: #23232e;
    margin-top: 0 !important;
    padding: 0;
  }

  .dropDown_list li {
    margin-left: 20px !important;
  }

  .nav_list li {
    margin-left: 40px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 100%;
    padding: 5px 0;
    transition-duration: 0.4s;
  }

  .nav_list li:hover {
    /* border: 1px solid #BEFF25; */
    cursor: pointer;
  }

  .nav_service {
    display: flex;
    position: relative;
    align-items: left;
    justify-content: flex-start;
  }

  .icon_serv_nav {
    position: absolute;
    top: 4px;
    left: 65px;
  }
  
  .dropDown_list {
    position: unset;
    top: 20px;
    margin-top: 20px;
  }
  
  .dropDown_list li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 5px;
    margin-top: 5px;
    color: white;
    opacity: 1;
    height: auto;
    border: unset !important;
  }

  .NL {
    color: white;
  }

  .NL_serv span {
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    width: 100%;
  }

  .NL_serv_div {
    width: 100%;
    flex-direction: column;
    align-content: left;
  }

  .mobile_menu {
    display: block;
    z-index: 1001;
  }

}

@media (max-width: 900px) {
  body, html {
    overflow-x: hidden !important;
  }

  .navbar_container {
    padding: 20px;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }

  .bttn_fale {
    display: none;
  }

  .cote_agr {
    display: block;
    /* margin-top: 25%; */
    padding: 5px;
    background-color: #f44336;
    border-radius: 8px;
    text-align: center;
    width: 120px;
    transition-duration: 0.4s;
    cursor: pointer;
  }

  .cote_agr:hover, .cote_agr:active {
    background-color: white;
    color: #f44336 !important;
    text-decoration: none !important;
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideinNav {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideout {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes growDown {
  0% {
      transform: scaleY(0)
  }
  80% {
      transform: scaleY(1.1)
  }
  100% {
      transform: scaleY(1)
  }
}
