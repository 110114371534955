.projects_container_main {
  padding: 150px 0 50px;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* overflow: hidden; */
  align-items: center;
  justify-content: center;
  color: white;
}

.img_grid_container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.img_proj_container {
  width: 100%; 
  height: 300px;
  overflow: hidden;
  align-self: center;
}

.img_proj {
  transition: all 0.5s;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: contrast(1.2);
}

.proj_text {
  display: none;
  transition: transform 0.5s;
  overflow: visible;
  font-family: sans-serif;
  text-align: center;
  margin: 5px 10%;
  z-index: 10;
}

.proj_local {
  font-family: 'Bebas Neue', cursive, sans-serif;
  font-weight: 200;
  color: #BEFF25;
}

.img_proj_container:hover .img_proj,
.img_proj_container:focus .img_proj {
  transform: scale(1.2);
}

.img_proj_container:hover {
  border: solid 2px #BEFF25;
}

.img_proj::before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(52, 73, 94, 0.75);
}

.img_proj_container:hover .img_proj::before,
.img_proj_container:focus .img_proj::before {
  display: block;
}

.img_proj_container:hover .proj_text,
.img_proj_container:focus .proj_text {
  display: block;
}

.btn_seeMore {
  background-color: transparent;
  border: solid 1px white;
  border-radius: 10px;
  color: white;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px 0;
  font-size: 16px;
  font-weight: 700;
  transition-duration: 0.4s;
  cursor: pointer;
  width: 150px;
}

.close_icon {
  color: #f44336;
}

.btn_seeMore:hover .close_icon,
.btn_seeMore:focus .close_icon {
  color: white;
}

.btn_seeMore:hover, .btn_seeMore:focus {
  background-color: #f44336;
}

@media (max-width: 900px) {
  .projects_container_main {
    padding-top: 50px;
    height: 100%;
    overflow-y: hidden;
  }
  
  .img_grid_container {
    grid-template-columns: 1fr 1fr;
  }
}